<template>
  <v-card-text
    data-cy="quotation-creation-timeout-card"
    class="mt-4 text-center"
  >
    <h4>
      <span
        v-t="
          'productDetails.quotationCreation.yourOrderHasBeenPlacedAndNowIsBeingProcessed'
        "
      />
    </h4>
    <p>
      <span
        v-t="
          'productDetails.quotationCreation.weAreSorryItMayTakeLongerThanWeExpected'
        "
      />
      <span
        v-t="'productDetails.quotationCreation.systemIsInMaintenanceRightNow'"
      />
    </p>
    <p>
      <span
        v-t="
          'productDetails.quotationCreation.youCanCheckBackInClientZoneLaterAndFindYourGeneratedQuotationIn'
        "
      />
      <router-link
        v-t="'productDetails.quotationCreation.invoicesQuotationsListHere'"
        target="_blank"
        rel="noreferrer"
        depressed
        :to="{ name: invoicesAndQuotationsRoute }"
        data-cy="I-and-Q-link"
      />
    </p>
    <p>
      <span v-t="'productDetails.quotationCreation.haveAWonderfulDay'" />
      <br>
      <span v-t="'productDetails.quotationCreation.easyRedmineTeam'" />
    </p>
  </v-card-text>
</template>

<script>
import { ROUTE_INVOICES_AND_QUOTATIONS } from '../../../../constants/routeNames'
export default {
  data () {
    return {
      invoicesAndQuotationsRoute: ROUTE_INVOICES_AND_QUOTATIONS
    }
  }
}
</script>
